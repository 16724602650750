















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name: 'AppContainerBase',
})
export default class AppContainerBase extends Vue {
	@Prop({
		type: String,
		required: true,
	})
	readonly title!: string;
}
